import { Modal, Divider, Select, TextInput } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import dayjs from 'dayjs'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import Button from 'src/components/Buttons/Button/Button'
import { NotInterestedReason } from 'src/graphql/types/applicants'
import IconCalendar from 'src/icons/IconCalendar'
import { formatSnakeValue } from 'src/lib/formatters'

const NotInterestedModal = ({ onSave, onClose }) => {
  const schema = z.object({
    notInterestedReason: z
      .union([z.string(), z.null()])
      .refine((val) => val !== null && val.trim().length > 0, {
        message: 'Reason is required',
      }),
    content: z.string().min(0).max(250),
  })
  const form = useForm({
    initialValues: {
      applicantStageDate: dayjs(),
      notInterestedReason: null,
      content: '',
    },
    validate: zodResolver(schema),
  })
  const handleSubmit = (values) => {
    // TODO: Include these fields
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSave(values)
    onClose()
  }

  const reasons = Object.values(NotInterestedReason).map((reason) => ({
    value: reason,
    label: formatSnakeValue(reason),
  }))

  return (
    <Modal
      opened={true}
      onClose={onClose}
      classNames={{ title: 'text-lg font-bold' }}
      title="Not Interested"
      centered
    >
      <form
        className="grid grid-cols-2 gap-4"
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <DatePickerInput
          label="Not Interested Date"
          placeholder="Pick date"
          valueFormat="MM/DD/YYYY"
          withAsterisk
          leftSection={
            <IconCalendar className="fill-none stroke-doubleNickel-gray-500" />
          }
          {...form.getInputProps('applicantStageDate')}
        />
        <Select
          label="Reason"
          placeholder="Select a reason"
          data={reasons}
          withAsterisk
          searchable
          {...form.getInputProps('notInterestedReason')}
        />
        <TextInput
          label="Notes"
          placeholder="Additional details or comments (optional)"
          className="col-span-2"
          {...form.getInputProps('content')}
        />
        <Divider className="col-span-2" />
        <Button text="Cancel" variant="outline" onClick={onClose} />
        <Button text="Save" type="submit" />
      </form>
    </Modal>
  )
}

export default NotInterestedModal
