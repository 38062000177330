import { Tooltip } from '@mantine/core'

const Row = ({
  children,
  icon: Icon,
  value,
  tooltipText,
  iconClassName,
  valueClassName,
  link = false,
}: {
  children?: any
  icon?: any
  value: any
  tooltipText?: string
  link?: boolean
  iconClassName?: string
  valueClassName?: string
}) => {
  const valueClass = `truncate text-sm ${
    valueClassName
      ? valueClassName
      : link
      ? 'font-medium text-doubleNickel-brand-500 hover:underline hover:cursor-pointer'
      : 'text-doubleNickel-gray-700'
  }`

  return (
    <Tooltip
      label={tooltipText}
      withArrow
      disabled={!tooltipText}
      position="top-start"
      className="text-xs"
    >
      <div className="flex flex-row items-center gap-2">
        {Icon && (
          <Icon
            className={`h-5 w-5 fill-none ${
              iconClassName ? iconClassName : 'stroke-doubleNickel-gray-600'
            }`}
          />
        )}

        <div className={valueClass}>{value}</div>
        {children}
      </div>
    </Tooltip>
  )
}

export default Row
