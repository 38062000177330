import { useState } from 'react'

import { ActionIcon, Menu, Card } from '@mantine/core'
import dayjs from 'dayjs'

import { ApplicantStage, HiringStage } from 'src/graphql/types/applicants'
import IconCancel from 'src/icons/IconCancel'
import IconCheckmarkCircle from 'src/icons/IconCheckmarkCircle'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconHourglass from 'src/icons/IconHourglass'
import { formatSnakeValue } from 'src/lib/formatters'

import Row from './Row/Row'
import DisqualifiedModal from './StageModals/DisqualifiedModal/DisqualifiedModal'
import NotInterestedModal from './StageModals/NotInterestedModal/NotInterestedModal'
import TerminatedModal from './StageModals/TerminatedModal/TerminatedModal'

const getStageSettings = (applicant) => {
  const stageMapping = {
    [ApplicantStage.PROSPECT]: {
      icon: IconHourglass,
      stroke: 'stroke-doubleNickel-gray-600',
      text: 'text-doubleNickel-gray-600',
    },
    [ApplicantStage.HIRED]: {
      icon: IconCheckmarkCircle,
      stroke: 'stroke-doubleNickel-success-600',
      text: 'text-doubleNickel-success-600',
    },
    [ApplicantStage.DISQUALIFIED]: {
      icon: IconCancel,
      stroke: 'stroke-doubleNickel-error-600',
      text: 'text-doubleNickel-error-600',
      backgroundColor: 'bg-doubleNickel-error-50',
      message: `${
        applicant.isPermanentlyDisqualified ? `Permanently` : `Temporarily`
      } Disqualified - ${formatSnakeValue(applicant.disqualifiedReason)}`,
    },
    [ApplicantStage.NOT_INTERESTED]: {
      icon: IconCancel,
      stroke: 'stroke-doubleNickel-gray-600',
      text: 'text-doubleNickel-gray-600',
      backgroundColor: 'bg-doubleNickel-gray-50',
      message: `${formatSnakeValue(applicant.notInterestedReason)}`,
    },
    [ApplicantStage.TERMINATED]: {
      icon: IconCancel,
      stroke: 'stroke-doubleNickel-error-600',
      text: 'text-doubleNickel-error-600',
      backgroundColor: 'bg-doubleNickel-error-50',
      message: `${
        applicant.eligibleForRehire ? `Eligible` : `Not Eligible`
      } For Rehire - ${formatSnakeValue(applicant.terminatedReason)}`,
    },
    default: {
      icon: IconCheckmarkCircle,
      stroke: 'stroke-doubleNickel-gray-600',
      text: 'text-doubleNickel-gray-600',
    },
  }

  return stageMapping[applicant.applicantStage] || stageMapping.default
}

const ApplicantStageRow = ({ applicant, handleStageChange }) => {
  const [ModalComponent, setModalComponent] = useState(null)
  const { icon, stroke, text, backgroundColor, message } =
    getStageSettings(applicant)

  const handleValueChange = (value) => {
    const modalMap = {
      [ApplicantStage.NOT_INTERESTED]: NotInterestedModal,
      [ApplicantStage.DISQUALIFIED]: DisqualifiedModal,
      [ApplicantStage.TERMINATED]: TerminatedModal,
    }

    const Modal = modalMap[value]
    if (Modal) {
      setModalComponent(() => () => (
        <Modal
          onSave={(values) =>
            handleStageChange({ applicantStage: value, ...values })
          }
          onClose={() => setModalComponent(null)}
        />
      ))
    } else {
      handleStageChange({
        applicantStage: value,
        applicantStageDate: new Date(),
      })
    }
  }

  const messageCard = (message, backgroundColor, textColor) => (
    <Card
      padding="xs"
      radius="md"
      className={`items-center ${backgroundColor} text-xs font-semibold ${textColor}`}
    >
      {message}
    </Card>
  )

  return (
    <>
      <Row
        icon={icon}
        iconClassName={stroke}
        value={
          applicant.applicantStage === 'PROSPECT'
            ? applicant.hiringStage.toLowerCase()
            : formatSnakeValue(applicant.applicantStage)
        }
        valueClassName={`font-bold ${text} capitalize`}
      >
        <Menu shadow="md" position="bottom-end">
          <Menu.Target>
            <ActionIcon
              variant="subtle"
              className="h-6 w-6 bg-doubleNickel-white"
            >
              <IconChevronDown className="fill-doubleNickel-white stroke-doubleNickel-gray-700" />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label className="text-sm font-semibold text-doubleNickel-gray-800">
              Prospect
            </Menu.Label>
            {Object.values(HiringStage).map((stage, index) => (
              <Menu.Item
                className="pl-6 text-xs font-medium text-doubleNickel-gray-600"
                key={index}
                onClick={() =>
                  handleStageChange({
                    hiringStage: stage,
                    applicantStage: 'PROSPECT',
                  })
                }
              >
                {formatSnakeValue(stage)}
              </Menu.Item>
            ))}
            {Object.values(ApplicantStage)
              .filter((stage) => stage !== 'PROSPECT')
              .map((stage, index) => (
                <Menu.Item
                  key={index}
                  onClick={() => handleValueChange(stage)}
                  className="text-sm font-semibold text-doubleNickel-gray-800"
                >
                  {formatSnakeValue(stage)}
                </Menu.Item>
              ))}
          </Menu.Dropdown>
        </Menu>
        <div className={`ml-auto text-xs font-semibold ${text}`}>
          {applicant.applicantStageDate &&
            `${dayjs(applicant.applicantStageDate).format('MM/DD/YYYY')}`}
        </div>
      </Row>
      {(applicant.applicantStage === ApplicantStage.NOT_INTERESTED ||
        applicant.applicantStage === ApplicantStage.TERMINATED ||
        applicant.applicantStage === ApplicantStage.DISQUALIFIED) &&
        messageCard(message, backgroundColor, text)}
      {ModalComponent && ModalComponent()}
    </>
  )
}

export default ApplicantStageRow
