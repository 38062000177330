import { useState } from 'react'

import { Menu, Modal, Tooltip } from '@mantine/core'

import DocumentPreview from 'src/components/Overlays/DocumentPreview/DocumentPreview'
import IconPaperclip from 'src/icons/IconPaperclip'

import StyledMenu from '../StyledMenu/StyledMenu'

const AttachmentsMenu = ({
  attachments,
  applicantId,
  disabled = false,
  disabledText = '',
}) => {
  const [documentForPreview, setDocumentForPreview] = useState(null)

  /************** HANDLERS  **************/
  const handlePreview = (document) => {
    setDocumentForPreview(document)
  }

  return (
    <>
      {documentForPreview && (
        <Modal
          size="90rem"
          lockScroll={false}
          classNames={{
            content: 'min-h-96 h-[100%]',
            body: 'flex h-[95%] flex-col overflow-auto',
          }}
          transitionProps={{ transition: 'fade', duration: 200 }}
          opened={documentForPreview}
          onClose={() => setDocumentForPreview(null)}
          withCloseButton={false}
        >
          <DocumentPreview
            documentId={documentForPreview.documentId}
            applicantId={applicantId}
            showStatus={true}
            showClosebutton={true}
            showBackButton={false}
            onBack={() => setDocumentForPreview(null)}
            showActions={false}
          />
        </Modal>
      )}
      <StyledMenu
        trigger="hover"
        openDelay={100}
        closeDelay={400}
        position="bottom-start"
        offset={4}
        withArrow
      >
        <Menu.Target>
          <div className="flex cursor-pointer flex-row items-center gap-1 text-sm text-doubleNickel-gray-700 hover:underline">
            <IconPaperclip className="fill-none stroke-doubleNickel-gray-500" />
            {attachments.length}{' '}
            {attachments.length === 1 ? ' attachment' : ' attachments'}{' '}
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          {disabled && (
            <Menu.Label className="text-doubleNickel-warning-500">
              {disabledText}
            </Menu.Label>
          )}

          {attachments.map((attachment) => (
            <Tooltip label={attachment.fileName} key={attachment.documentId}>
              <Menu.Item
                disabled={disabled}
                onClick={() => {
                  handlePreview(attachment)
                }}
              >
                {attachment.fileName}
              </Menu.Item>
            </Tooltip>
          ))}
        </Menu.Dropdown>
      </StyledMenu>
    </>
  )
}

export default AttachmentsMenu
