import { ActivityLog } from 'types/graphql'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import { formatDate } from 'src/lib/formatters'
import { RecruitersContext } from 'src/context/RecruitersContext'
import { useContext } from 'react'
import { replaceRecruitersIdWithName } from 'src/lib/recruiters.utils'

interface NoteLogCardProps {
  activityLog: ActivityLog
  showBadge?: boolean
  smallVersion?: boolean
}

const NoteLog = ({
  activityLog,
  showBadge = false,
  smallVersion = false,
}: NoteLogCardProps) => {
  const recruiters = useContext(RecruitersContext)
  return (
    <div
      className={`flex w-full py-4 ${
        smallVersion ? 'flex-col-reverse' : 'flex-row'
      } gap-2 border-b-[1px] border-doubleNickel-gray-300 px-2 py-2`}
    >
      <div
        className={`flex flex-1 ${
          smallVersion ? 'flex-row justify-between' : 'flex-col'
        } text-xs text-doubleNickel-gray-500`}
      >
        <div>
          {activityLog.employee &&
            `${activityLog.employee.firstName} ${activityLog.employee.lastName}`}{' '}
        </div>
        <div>{formatDate(activityLog.createdAt)}</div>
      </div>
      <div className="flex flex-1 flex-row items-center gap-2">
        <div className="break-words text-sm text-doubleNickel-gray-900">
          {replaceRecruitersIdWithName(activityLog.content, recruiters)}
        </div>

        {showBadge && (
          <StyledBadge color={'orange'} textTransform="capitalize">
            {activityLog.type}
          </StyledBadge>
        )}
      </div>
    </div>
  )
}

export default NoteLog
