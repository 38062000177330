const IconAddress = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
    >
      <path
        d="M7.99992 10.8334C9.38063 10.8334 10.4999 9.71413 10.4999 8.33342C10.4999 6.9527 9.38063 5.83341 7.99992 5.83341C6.61921 5.83341 5.49992 6.9527 5.49992 8.33342C5.49992 9.71413 6.61921 10.8334 7.99992 10.8334Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99992 18.3334C11.3333 15.0001 14.6666 12.0153 14.6666 8.33342C14.6666 4.65152 11.6818 1.66675 7.99992 1.66675C4.31802 1.66675 1.33325 4.65152 1.33325 8.33342C1.33325 12.0153 4.66659 15.0001 7.99992 18.3334Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconAddress
