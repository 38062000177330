const IconHourglass = (props) => {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1355 1.66675H1.86462C1.47919 1.66675 1.16675 1.97919 1.16675 2.36462C1.16675 4.58565 2.04905 6.71572 3.61956 8.28622L4.63555 9.30221C4.73644 9.40311 4.78689 9.45355 4.823 9.502C5.04331 9.79751 5.04331 10.2027 4.823 10.4982C4.78689 10.5466 4.73644 10.5971 4.63555 10.6979L3.61956 11.7139C2.04905 13.2844 1.16675 15.4145 1.16675 17.6355C1.16675 18.021 1.47919 18.3334 1.86462 18.3334H12.1355C12.521 18.3334 12.8334 18.021 12.8334 17.6355C12.8334 15.4145 11.9511 13.2844 10.3806 11.7139L9.36462 10.6979C9.26372 10.5971 9.21328 10.5466 9.17716 10.4982C8.95685 10.2027 8.95685 9.79751 9.17716 9.502C9.21328 9.45355 9.26372 9.40311 9.36462 9.30221L10.3806 8.28622C11.9511 6.71572 12.8334 4.58565 12.8334 2.36462C12.8334 1.97919 12.521 1.66675 12.1355 1.66675Z"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default IconHourglass
