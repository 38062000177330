const IconPhone = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.98356 6.37779C6.56356 7.58581 7.35422 8.71801 8.35553 9.71933C9.35685 10.7206 10.4891 11.5113 11.6971 12.0913C11.801 12.1412 11.8529 12.1661 11.9187 12.1853C12.1523 12.2534 12.4392 12.2045 12.637 12.0628C12.6927 12.0229 12.7403 11.9753 12.8356 11.88C13.1269 11.5887 13.2726 11.443 13.4191 11.3478C13.9715 10.9886 14.6837 10.9886 15.2361 11.3478C15.3825 11.443 15.5282 11.5887 15.8196 11.88L15.9819 12.0424C16.4248 12.4853 16.6462 12.7067 16.7665 12.9446C17.0058 13.4175 17.0058 13.9761 16.7665 14.449C16.6462 14.6869 16.4248 14.9083 15.9819 15.3512L15.8506 15.4825C15.4092 15.9239 15.1886 16.1446 14.8885 16.3131C14.5556 16.5001 14.0385 16.6346 13.6567 16.6334C13.3126 16.6324 13.0774 16.5657 12.607 16.4322C10.0792 15.7147 7.69387 14.361 5.70388 12.371C3.7139 10.381 2.36017 7.99569 1.6427 5.46786C1.50919 4.99749 1.44244 4.7623 1.44141 4.41818C1.44028 4.03633 1.57475 3.51925 1.76176 3.18633C1.9303 2.88631 2.15098 2.66563 2.59233 2.22428L2.72369 2.09292C3.16656 1.65005 3.388 1.42861 3.62581 1.30833C4.09878 1.0691 4.65734 1.0691 5.1303 1.30832C5.36812 1.42861 5.58955 1.65005 6.03242 2.09291L6.19481 2.25531C6.48615 2.54665 6.63182 2.69231 6.72706 2.8388C7.08622 3.3912 7.08622 4.10336 6.72706 4.65576C6.63182 4.80225 6.48615 4.94791 6.19481 5.23925C6.09955 5.33451 6.05192 5.38214 6.01206 5.43782C5.87038 5.63568 5.82146 5.92256 5.88957 6.15619C5.90873 6.22193 5.93367 6.27389 5.98356 6.37779Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconPhone
