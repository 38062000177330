const IconPlane = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74928 11.25L17.4993 2.50002M8.85559 11.5234L11.0457 17.1551C11.2386 17.6512 11.3351 17.8993 11.4741 17.9717C11.5946 18.0345 11.7381 18.0345 11.8587 17.9719C11.9978 17.8997 12.0946 17.6517 12.2881 17.1558L17.78 3.08269C17.9547 2.63504 18.0421 2.41121 17.9943 2.26819C17.9528 2.14398 17.8553 2.04651 17.7311 2.00501C17.5881 1.95723 17.3643 2.04458 16.9166 2.21927L2.84349 7.71122C2.34759 7.90474 2.09965 8.0015 2.02739 8.14059C1.96475 8.26116 1.96483 8.4047 2.02761 8.5252C2.10004 8.66421 2.3481 8.76067 2.84422 8.95361L8.47589 11.1437C8.5766 11.1829 8.62695 11.2024 8.66935 11.2327C8.70693 11.2595 8.7398 11.2924 8.7666 11.3299C8.79685 11.3723 8.81643 11.4227 8.85559 11.5234Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconPlane
