const IconClock = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0445 8.7441C14.813 10.919 13.5807 12.9571 11.5416 14.1344C8.15365 16.0904 3.82155 14.9297 1.86554 11.5417L1.6572 11.1809M0.955301 7.25602C1.1868 5.08111 2.41913 3.043 4.45828 1.8657C7.84619 -0.0903117 12.1783 1.07047 14.1343 4.45838L14.3426 4.81922M0.911133 13.055L1.52118 10.7783L3.79788 11.3884M12.202 4.61173L14.4787 5.22177L15.0888 2.94506M7.99995 4.25004V8.00004L10.0833 9.25004"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconClock
