import {
  Modal,
  Divider,
  Select,
  TextInput,
  RadioGroup,
  Radio,
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import dayjs from 'dayjs'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import Button from 'src/components/Buttons/Button/Button'
import { DisqualifiedReason } from 'src/graphql/types/applicants'
import IconCalendar from 'src/icons/IconCalendar'
import { formatSnakeValue } from 'src/lib/formatters'

const DisqualifiedModal = ({ onSave, onClose }) => {
  const schema = z.object({
    disqualifiedReason: z
      .union([z.string(), z.null()])
      .refine((val) => val !== null && val.trim().length > 0, {
        message: 'Reason is required',
      }),
    isPermanentlyDisqualified: z
      .string()
      .refine((val) => val === 'true' || val === 'false', {
        message: 'Permanently disqualified status is required',
      }),
    content: z.string().min(0).max(250),
  })
  const form = useForm({
    initialValues: {
      applicantStageDate: dayjs(),
      disqualifiedReason: null,
      content: '',
      isPermanentlyDisqualified: 'false',
    },
    validate: zodResolver(schema),
  })
  const handleSubmit = (values) => {
    // TODO: Include these fields
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isPermanentlyDisqualified, ...rest } = values

    onSave({
      ...rest,
      isPermanentlyDisqualified:
        isPermanentlyDisqualified === 'true' ||
        isPermanentlyDisqualified === true,
    })
    onClose()
  }

  const reasons = Object.values(DisqualifiedReason).map((reason) => ({
    value: reason,
    label: formatSnakeValue(reason),
  }))

  return (
    <Modal
      opened={true}
      onClose={onClose}
      classNames={{ title: 'text-lg font-bold' }}
      title="Disqualified"
      centered
    >
      <form
        className="grid grid-cols-2 gap-4"
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <DatePickerInput
          label="Disqualification Date"
          placeholder="Pick date"
          valueFormat="MM/DD/YYYY"
          required
          leftSection={
            <IconCalendar className="fill-none stroke-doubleNickel-gray-500" />
          }
          {...form.getInputProps('applicantStageDate')}
        />
        <Select
          label="Reason"
          placeholder="Select a reason"
          data={reasons}
          searchable
          required
          {...form.getInputProps('disqualifiedReason')}
        />
        <RadioGroup
          className="col-span-2 gap-4"
          label="Permanently disqualified?"
          required
          {...form.getInputProps('isPermanentlyDisqualified')}
        >
          <div className="flex items-center gap-4">
            <Radio
              label="Yes"
              value="true"
              size="xs"
              classNames={{
                label: 'text-sm',
                radio: 'checked:bg-doubleNickel-brand-500',
              }}
            />
            <Radio
              label="No"
              value="false"
              size="xs"
              classNames={{
                label: 'text-sm',
                radio: 'checked:bg-doubleNickel-brand-500',
              }}
            />
          </div>
        </RadioGroup>
        <TextInput
          label="Notes"
          placeholder="Additional details or comments (optional)"
          className="col-span-2"
          {...form.getInputProps('content')}
        />
        <Divider className="col-span-2" />
        <Button text="Cancel" variant="outline" onClick={onClose} />
        <Button text="Save" type="submit" />
      </form>
    </Modal>
  )
}

export default DisqualifiedModal
