import { useMutation } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { CREATE_ACTIVITY_LOG } from 'src/graphql/activityLogs.graphql'

import CallInput from './CallInput/CallInput'
import NoteInput from './NoteInput/NoteInput'
import SMSInput from './SMSInput/SMSInput'

const ActivityLogInput = ({
  applicantId,
  refetchQueries,
  communication = 'NOTE',
  onRegisterCall,
}) => {
  // const defaultTab = 'note'
  // const [activeTab, setActiveTab] = useState<string>(defaultTab)

  const [createActivityLog] = useMutation(CREATE_ACTIVITY_LOG, {
    refetchQueries,
    onCompleted: ({ createActivityLog }) => {
      if (onRegisterCall) {
        onRegisterCall(createActivityLog)
      }
    },
    onError: (error) => {
      const errorMessage =
        error.graphQLErrors?.[0]?.message || 'Something went wrong'
      toast(errorMessage, 'error')
    },
  })

  const onCreate = (values) => {
    createActivityLog({
      variables: {
        input: {
          ...values,
          applicantId,
        },
      },
    })
  }

  if (communication === 'SMS') {
    return <SMSInput sendSMS={onCreate} applicantId={applicantId} />
  } else if (communication === 'NOTE') {
    return <NoteInput onCreate={onCreate} />
  }

  return <CallInput onCreate={onCreate} />
}

export default ActivityLogInput
