const IconLuggage = (props) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66699 16.5V4.83333C6.66699 4.05836 6.66699 3.67087 6.75218 3.35295C6.98335 2.49022 7.65721 1.81635 8.51995 1.58519C8.83786 1.5 9.22535 1.5 10.0003 1.5C10.7753 1.5 11.1628 1.5 11.4807 1.58519C12.3434 1.81635 13.0173 2.49022 13.2485 3.35295C13.3337 3.67087 13.3337 4.05836 13.3337 4.83333V10.25M13.3337 13.5833V16.5M5.66699 16.5H14.3337C15.7338 16.5 16.4339 16.5 16.9686 16.2275C17.439 15.9878 17.8215 15.6054 18.0612 15.135C18.3337 14.6002 18.3337 13.9001 18.3337 12.5V8.83333C18.3337 7.4332 18.3337 6.73314 18.0612 6.19836C17.8215 5.72795 17.439 5.3455 16.9686 5.10582C16.4339 4.83333 15.7338 4.83333 14.3337 4.83333H5.66699C4.26686 4.83333 3.5668 4.83333 3.03202 5.10582C2.56161 5.3455 2.17916 5.72795 1.93948 6.19836C1.66699 6.73314 1.66699 7.4332 1.66699 8.83333V12.5C1.66699 13.9001 1.66699 14.6002 1.93948 15.135C2.17916 15.6054 2.56161 15.9878 3.03202 16.2275C3.5668 16.5 4.26686 16.5 5.66699 16.5ZM10.667 13.5833H14.7503C14.9837 13.5833 15.1004 13.5833 15.1895 13.5379C15.2679 13.498 15.3316 13.4342 15.3716 13.3558C15.417 13.2667 15.417 13.15 15.417 12.9167V10.9167C15.417 10.6833 15.417 10.5666 15.3716 10.4775C15.3316 10.3991 15.2679 10.3354 15.1895 10.2954C15.1004 10.25 14.9837 10.25 14.7503 10.25H10.667C10.4336 10.25 10.317 10.25 10.2278 10.2954C10.1494 10.3354 10.0857 10.3991 10.0457 10.4775C10.0003 10.5666 10.0003 10.6833 10.0003 10.9167V12.9167C10.0003 13.15 10.0003 13.2667 10.0457 13.3558C10.0857 13.4342 10.1494 13.498 10.2278 13.5379C10.317 13.5833 10.4336 13.5833 10.667 13.5833Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconLuggage
