import { ActivityLog, Applicant } from 'types/graphql'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import AttachmentsMenu from 'src/components/Overlays/AttachmentsMenu/AttachmentsMenu'
import { SMSDirection } from 'src/graphql/types/activityLogs'
import { formatDate } from 'src/lib/formatters'

interface SMSLogCardProps {
  activityLog: ActivityLog
  applicant?: Applicant
  showBadge?: boolean
}

const SMSLog = ({
  activityLog,
  applicant,
  showBadge = false,
}: SMSLogCardProps) => {
  let displayName = ''

  if (
    activityLog.smsDirection === SMSDirection.OUTBOUND &&
    activityLog.employee
  ) {
    displayName = `${activityLog.employee.firstName} ${activityLog.employee.lastName}`
  } else if (activityLog.smsDirection === SMSDirection.INBOUND && applicant) {
    displayName = `${applicant.firstName} ${applicant.lastName}`
  }

  return (
    <div
      className={`flex w-[75%] flex-col gap-2 rounded-t-xl p-4 ${
        activityLog?.smsDirection === SMSDirection.OUTBOUND
          ? 'ml-auto rounded-bl-xl bg-doubleNickel-brand-25'
          : 'mr-auto rounded-br-xl bg-doubleNickel-gray-50'
      }`}
    >
      <div className="flex flex-row items-center gap-2">
        <div className="overflow-hidden hyphens-auto break-words text-sm text-doubleNickel-gray-900">
          {activityLog.content}
        </div>
        {showBadge && (
          <StyledBadge color={'orange'} textTransform="capitalize">
            {activityLog.type}
          </StyledBadge>
        )}
      </div>

      {activityLog?.documents && activityLog?.documents.length > 0 && (
        <div>
          <AttachmentsMenu
            attachments={activityLog.documents}
            applicantId={applicant.applicantId}
          />
        </div>
      )}

      <div
        className={`flex w-full flex-row justify-between text-xs text-doubleNickel-gray-500`}
      >
        <div>{displayName}</div>
        <div>{formatDate(activityLog.createdAt)}</div>
      </div>
    </div>
  )
}

export default SMSLog
