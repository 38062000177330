const IconNote = (props) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66634 1.8913V5.33341C9.66634 5.80012 9.66634 6.03348 9.75717 6.21174C9.83706 6.36854 9.96455 6.49602 10.1213 6.57592C10.2996 6.66675 10.533 6.66675 10.9997 6.66675H14.4418M11.333 10.8334H4.66634M11.333 14.1667H4.66634M6.33301 7.50002H4.66634M9.66634 1.66669H5.33301C3.93288 1.66669 3.23281 1.66669 2.69803 1.93917C2.22763 2.17885 1.84517 2.56131 1.60549 3.03171C1.33301 3.56649 1.33301 4.26656 1.33301 5.66669V14.3334C1.33301 15.7335 1.33301 16.4336 1.60549 16.9683C1.84517 17.4387 2.22763 17.8212 2.69803 18.0609C3.23281 18.3334 3.93288 18.3334 5.33301 18.3334H10.6663C12.0665 18.3334 12.7665 18.3334 13.3013 18.0609C13.7717 17.8212 14.1542 17.4387 14.3939 16.9683C14.6663 16.4336 14.6663 15.7335 14.6663 14.3334V6.66669L9.66634 1.66669Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconNote
