import { useContext, useState } from 'react'
import {
  ActionIcon,
  Loader,
  Avatar,
  Menu,
  Divider,
  Tooltip,
  Box,
  Button as MantineButton,
} from '@mantine/core'
import TimeAgo from 'react-timeago'
import { Link, navigate, routes } from '@redwoodjs/router'
import { useQuery, useMutation } from '@redwoodjs/web'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_APPLICANT_BY_ID } from 'src/graphql/applicants.graphql'
import { UPDATE_APPLICANT } from 'src/graphql/applicants.graphql'
import { LicenseClass } from 'src/graphql/types/licenses'
import IconAddress from 'src/icons/IconAddress'
import IconAlert from 'src/icons/IconAlert'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconClock from 'src/icons/IconClock'
import IconDocumentCheck from 'src/icons/IconDocumentCheck'
import IconLuggage from 'src/icons/IconLuggage'
import IconNavigate from 'src/icons/IconNavigate'
import IconPhone from 'src/icons/IconPhone'
import {
  buildFullName,
  formatExperience,
  formatSidebarAddress,
} from 'src/lib/formatters'
import ApplicantStageRow from './components/ApplicantStageRow'
import Row from './components/Row/Row'
import SidebarActivityLogs from './components/SidebarActivityLogs'
import StepItem from './components/StepItem/StepItem'
import {
  unformatPhoneNumber,
  formatInternationalPhoneNumber,
} from 'src/lib/phone.utils'
import { buildApplicationUrl } from 'src/lib/url.utils'
import IconLink from 'src/icons/IconLink'
import IconNote from 'src/icons/IconNote'
import IconRightArrow from 'src/icons/IconRightArrow'
import IconMessage from 'src/icons/IconMessage'
import IconList from 'src/icons/IconList'
import { RecruitersContext } from 'src/context/RecruitersContext'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const EMPTY_VALUE = '-'

const CustomTab = ({
  applicant,
  selectedTab,
  value,
  label,
  setSelectedTab,
  children,
}) => {
  const isSelected = selectedTab === value
  const isActivityLog = value !== 'HIRING_STEPS'

  return (
    <Box
      className={`font-regular flex flex-row items-center gap-2 text-nowrap p-1 text-sm transition-all  hover:rounded hover:bg-doubleNickel-gray-100 ${
        isSelected
          ? 'flex-1 border-b-2 border-doubleNickel-brand-500 text-doubleNickel-brand-500'
          : 'text-doubleNickel-gray-700'
      }`}
      onClick={() => setSelectedTab(value)}
    >
      <Tooltip label={label}>
        <ActionIcon variant="transparent">{children}</ActionIcon>
      </Tooltip>
      {isSelected && <>{label}</>}
      {
        <Tooltip label={`Go to activity log`}>
          <ActionIcon
            variant="subtle"
            className={`ml-auto ${
              isSelected && isActivityLog ? 'flex' : 'hidden'
            }`}
            onClick={() =>
              navigate(
                routes.applicantDetails({
                  id: applicant.applicantId,
                  tab: 'activitylog',
                  activityType: value,
                })
              )
            }
          >
            <IconRightArrow
              className={`h-4 fill-none stroke-doubleNickel-brand-500 `}
            />
          </ActionIcon>
        </Tooltip>
      }
    </Box>
  )
}

const ApplicantSidebar = ({
  applicantId,
  showNavigation = false,
  onRegisterCall = () => {},
  onPhoneCall = () => {},
}: {
  applicantId: string
  showNavigation: boolean
  onRegisterCall?: (activityLog) => void
  onPhoneCall?: (applicant) => void
}) => {
  const recruiters = useContext(RecruitersContext)
  const [selectedTab, setSelectedTab] = useState<string | null>('HIRING_STEPS')

  const { data, loading: loadingApplicant } = useQuery(GET_APPLICANT_BY_ID, {
    variables: {
      id: applicantId,
    },
    onError,
  })
  const applicant = data?.applicant || null

  const [updateApplicant] = useMutation(UPDATE_APPLICANT, {
    refetchQueries: [
      {
        query: GET_APPLICANT_BY_ID,
        variables: {
          id: applicantId,
        },
      },
    ],
    onCompleted: () => {
      toast('Applicant updated successfully', 'success')
    },
    onError,
  })

  const handleRecruiterChange = (value) => {
    updateApplicant({
      variables: {
        id: applicantId,
        input: {
          recruiterId: value,
        },
      },
    })
  }

  const handleApplicantStageChange = (input) => {
    updateApplicant({
      variables: {
        id: applicantId,
        input,
      },
    })
  }

  const AvatarIcon = () => {
    return (
      <Avatar
        size={20}
        variant="transparent"
        classNames={{
          placeholder: 'bg-doubleNickel-brand-25 text-doubleNickel-brand-500',
        }}
      >
        {`${applicant.recruiter?.firstName.charAt(
          0
        )}${applicant.recruiter?.lastName.charAt(0)}`}
      </Avatar>
    )
  }

  const licenseInformation = (license) => {
    const licenseClassLabel =
      license.licenseClass == LicenseClass.NO_CDL
        ? 'No CDL'
        : `Class ${license.licenseClass}`

    return (
      <Row
        tooltipText="CDL & Endorsements"
        icon={IconDocumentCheck}
        value={`${licenseClassLabel} ${
          license?.endorsements && license?.endorsements.length > 0
            ? ` (${license?.endorsements.join(', ')})`
            : ''
        }`}
      />
    )
  }

  const fullName = applicant
    ? buildFullName({
        firstName: applicant.firstName,
        middleName: applicant.middleName,
        lastName: applicant.lastName,
      })
    : ''

  return (
    <div className="h-[100%]">
      {loadingApplicant && (
        <Loader size="md" className="flex w-full justify-center" />
      )}
      {applicant && (
        <div className="flex h-full flex-col gap-3 overflow-auto">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-1">
              <div className="flex flex-row items-center justify-between text-lg font-semibold text-doubleNickel-gray-900">
                {fullName}
                <Tooltip
                  label={"Copy this applicant's application url to clipboard"}
                >
                  <ActionIcon
                    variant="subtle"
                    className="hover:bg-doubleNickel-gray-100"
                    onClick={() => {
                      const url = buildApplicationUrl(
                        applicant.application.applicationId
                      )
                      navigator.clipboard.writeText(url)
                      toast('Copied to clipboard', 'success')
                    }}
                  >
                    <IconLink className="h-5 fill-none stroke-doubleNickel-gray-700" />
                  </ActionIcon>
                </Tooltip>
              </div>
              <ApplicantStageRow
                applicant={applicant}
                handleStageChange={handleApplicantStageChange}
              />
            </div>
            <Row
              tooltipText="Phone Number"
              icon={IconPhone}
              value={
                <Box
                  onClick={(event) => {
                    event.stopPropagation()
                    onPhoneCall({
                      applicantId: applicant.applicantId,
                      applicantFullName: buildFullName(applicant),
                      phone: unformatPhoneNumber(applicant.phone),
                    })
                  }}
                  className={`cursor-pointer text-sm font-medium text-doubleNickel-brand-500 hover:underline`}
                >
                  {formatInternationalPhoneNumber(applicant.phone)}
                </Box>
              }
              link
            />
            <Row
              tooltipText="Job Listing"
              icon={IconLuggage}
              value={applicant.jobListing.title}
            />
            {applicant.address && (
              <Row
                tooltipText="Address"
                icon={IconAddress}
                value={formatSidebarAddress(applicant.address)}
              />
            )}
            {applicant.yearsOfExperience !== null &&
              applicant.yearsOfExperience !== undefined && (
                <Row
                  tooltipText="CDL Experience"
                  icon={IconClock}
                  value={formatExperience(applicant.yearsOfExperience)}
                />
              )}
            {applicant.application?.accidents &&
              applicant.application.accidents.length > 0 && (
                <Row
                  tooltipText="Accidents"
                  icon={IconAlert}
                  value={`${applicant.application.accidents.length} accident(s) registered`}
                />
              )}
            {applicant.application?.license?.licenseClass &&
              licenseInformation(applicant.application.license)}
            <Row
              icon={AvatarIcon}
              value={`${applicant.recruiter?.firstName ?? EMPTY_VALUE}
              ${applicant.recruiter?.lastName ?? ' '}`}
            >
              <Menu shadow="md" position="bottom-end">
                <Menu.Target>
                  <ActionIcon
                    variant="subtle"
                    className="h-6 w-6 bg-doubleNickel-white"
                  >
                    <IconChevronDown className="fill-doubleNickel-white stroke-doubleNickel-gray-700" />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  {recruiters.map((employee) => (
                    <Menu.Item
                      key={employee.value}
                      onClick={() => handleRecruiterChange(employee.value)}
                    >
                      {employee.label}
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
            </Row>
            <div className="truncate text-xs text-doubleNickel-gray-500">
              Started <TimeAgo date={applicant.createdAt}></TimeAgo> | Last
              modified <TimeAgo date={applicant.updatedAt}></TimeAgo>
            </div>
          </div>

          <Divider />
          <div className="flex flex-row items-center overflow-auto text-lg font-semibold text-doubleNickel-gray-900">
            <CustomTab
              applicant={applicant}
              selectedTab={selectedTab}
              value="HIRING_STEPS"
              label="Hiring Steps"
              setSelectedTab={setSelectedTab}
            >
              <IconList
                className={`h-5 fill-none ${
                  selectedTab === 'HIRING_STEPS'
                    ? 'stroke-doubleNickel-brand-500'
                    : 'stroke-doubleNickel-gray-700'
                }`}
              />
            </CustomTab>
            <CustomTab
              applicant={applicant}
              selectedTab={selectedTab}
              value="CALL"
              label="Phone Calls"
              setSelectedTab={setSelectedTab}
            >
              <IconPhone
                className={`h-5 fill-none ${
                  selectedTab === 'CALL'
                    ? 'stroke-doubleNickel-brand-500'
                    : 'stroke-doubleNickel-gray-700'
                }`}
              />
            </CustomTab>
            <CustomTab
              applicant={applicant}
              selectedTab={selectedTab}
              value="SMS"
              label="Texts"
              setSelectedTab={setSelectedTab}
            >
              <IconMessage
                className={`h-5 fill-none ${
                  selectedTab === 'SMS'
                    ? 'stroke-doubleNickel-brand-500'
                    : 'stroke-doubleNickel-gray-700'
                }`}
              />
            </CustomTab>
            <CustomTab
              applicant={applicant}
              selectedTab={selectedTab}
              value="NOTE"
              label="Notes"
              setSelectedTab={setSelectedTab}
            >
              <IconNote
                className={`h-5 fill-none ${
                  selectedTab === 'NOTE'
                    ? 'stroke-doubleNickel-brand-500'
                    : 'stroke-doubleNickel-gray-700'
                }`}
              />
            </CustomTab>
          </div>
          {selectedTab == 'HIRING_STEPS' &&
            applicant.steps.items.length > 0 && (
              <div className="flex flex-1 flex-col gap-3 overflow-auto ">
                {applicant.steps.items.map((step) => (
                  <StepItem
                    key={step.stepId}
                    step={step}
                    applicantId={applicant.applicantId}
                  />
                ))}
              </div>
            )}
          {(selectedTab === 'NOTE' ||
            selectedTab === 'SMS' ||
            selectedTab == 'CALL') && (
            <div className={`flex flex-1 flex-col gap-4 overflow-hidden`}>
              <SidebarActivityLogs
                applicant={applicant}
                communication={selectedTab}
                onRegisterCall={onRegisterCall}
              />
            </div>
          )}
          {showNavigation && (
            <MantineButton
              classNames={{
                root: 'bg-doubleNickel-brand-500 text-white text-xs rounded-lg mt-auto min-h-8 hover:bg-doubleNickel-brand-700',
              }}
              component={Link}
              to={routes.applicantDetails({
                id: applicant.applicantId,
                tab: 'application',
              })}
              rightSection={
                <IconNavigate className="h-4 fill-none stroke-white" />
              }
            >
              Go to applicant
            </MantineButton>
          )}
        </div>
      )}
    </div>
  )
}

export default ApplicantSidebar
