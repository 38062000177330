import { ActionIcon } from '@mantine/core'
import { DateTimePicker } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import Button from 'src/components/Buttons/Button/Button'
import { ActivityLogType, CallDirection } from 'src/graphql/types/activityLogs'
import IconPlane from 'src/icons/IconPlane'

const CallInput = ({ onCreate }) => {
  const callInputSchema = z.object({
    activityDate: z.date(),
  })

  const form = useForm({
    initialValues: {
      activityDate: new Date(),
    },

    validate: zodResolver(callInputSchema),
  })

  const onFormSubmit = (values) => {
    onCreate({
      type: ActivityLogType.CALL,
      callDirection: CallDirection.OUTBOUND,
      ...values,
    })
    form.reset()
    form.setFieldValue('activityDate', null)
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => onFormSubmit(values))}
      className="flex flex-row items-center gap-2"
    >
      <DateTimePicker
        placeholder="Enter date here..."
        className="flex-1"
        valueFormat="MM/DD/YYYY hh:mm A"
        withAsterisk
        maxDate={new Date()}
        clearable
        {...form.getInputProps('activityDate')}
      />
      <Button text="Register Call" disabled={!form.isValid()} type="submit" />
    </form>
  )
}

export default CallInput
