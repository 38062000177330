import { Textarea, Menu } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'
import Button from 'src/components/Buttons/Button/Button'
import { ActivityLogType } from 'src/graphql/types/activityLogs'
import StyledMenu from 'src/components/Overlays/StyledMenu/StyledMenu'
import { useContext, useEffect, useRef, useState } from 'react'
import { RecruitersContext } from 'src/context/RecruitersContext'
import { replaceNamesWithRecruitersId } from 'src/lib/recruiters.utils'

const MAX_NOTE_LENGTH = 500

enum TagAction {
  MENTION = 'MENTION',
}

enum TagType {
  EMPLOYEE = 'EMPLOYEE',
}

const NoteInput = ({ onCreate }) => {
  const inputRef = useRef(null)
  const [opened, setOpened] = useState(false)
  const [selectedWord, setSelectedWord] = useState('')
  const [textContent, setTextContent] = useState('')
  const recruiters = useContext(RecruitersContext)
  const noteInputSchema = z.object({
    content: z
      .string()
      .min(1, { message: 'Enter at least 1 character.' })
      .max(MAX_NOTE_LENGTH, `Characters limit is ${MAX_NOTE_LENGTH}.`),
  })

  const form = useForm({
    initialValues: {
      content: '',
    },
    validateInputOnChange: true,
    validate: zodResolver(noteInputSchema),
  })

  const generateTags = (content) => {
    const tags = content?.match(/@{{employee:(.*?)}}/g)
    return (
      tags?.map((tag) => {
        const id = tag.split(':')[1].slice(0, -2)
        return {
          referenceId: recruiters.find((recruiter) => recruiter.value === id)
            ?.value,
          tagAction: TagAction.MENTION,
          tagType: TagType.EMPLOYEE,
        }
      }) ?? []
    )
  }

  const onFormSubmit = (values) => {
    values.content = replaceNamesWithRecruitersId(values.content, recruiters)
    const tags = generateTags(values.content)
    onCreate({
      type: ActivityLogType.NOTE,
      tags: tags,
      ...values,
    })
    form.reset()
  }

  useEffect(() => {
    const cursorPosition = inputRef.current.selectionStart
    const textBeforeCursor = form.values.content.slice(0, cursorPosition)
    const temp = textBeforeCursor.split(/\s+/)
    const currentWord = temp[temp.length - 1]
    if (currentWord?.startsWith('@')) {
      setSelectedWord(currentWord.slice(1))
      setOpened(true)
    } else {
      setOpened(false)
    }
  }, [form.values.content])

  return (
    <form
      onSubmit={form.onSubmit((values) => onFormSubmit(values))}
      className="flex flex-row items-center gap-2"
    >
      <StyledMenu opened={opened}>
        <Menu.Target>
          <Textarea
            ref={inputRef}
            placeholder="Write a note here..."
            autosize={true}
            maxRows={3}
            className="flex-1"
            maxLength={MAX_NOTE_LENGTH}
            withAsterisk
            value={form.values.content}
            error={form.errors.content}
            onChange={(e) => {
              form.setFieldValue('content', e.currentTarget.value)
            }}
          />
        </Menu.Target>
        <Menu.Dropdown
          onFocus={() => {
            inputRef.current.focus()
          }}
        >
          {recruiters
            .filter((recruiter) =>
              recruiter.label.toLowerCase().includes(selectedWord.toLowerCase())
            )
            .map((recruiter) => (
              <Menu.Item
                key={recruiter.value}
                value={recruiter.value}
                onClick={(e) => {
                  const content = form.values.content
                  const cursorPosition = inputRef.current.selectionStart
                  const textBeforeCursor = content.slice(0, cursorPosition)
                  const temp = textBeforeCursor.split(/\s+/)
                  const currentWord = temp[temp.length - 1]
                  const newContent =
                    content.slice(0, cursorPosition - currentWord.length) +
                    `@${recruiter.label}` +
                    content.slice(cursorPosition)
                  form.setFieldValue('content', newContent)
                  setOpened(false)
                }}
              >
                {recruiter.label}
              </Menu.Item>
            ))}
        </Menu.Dropdown>
      </StyledMenu>

      <Button text="Save" disabled={!form.isValid()} type="submit" />
    </form>
  )
}

export default NoteInput
